import React, { ErrorInfo, ReactNode } from 'react';
import * as Sentry from '@sentry/browser';
import { config } from '../config';

export class ErrorBoundary extends React.Component<{ children?: ReactNode }, {}> {
    componentDidCatch(error: Error, errorInfo: ErrorInfo) {
        if (config.sentryToken !== 'DELETE-ME') {
            Sentry.withScope((scope) => {
                scope.setExtra('componentStack', errorInfo.componentStack);
                Sentry.captureException(error);
            });
        } else {
            console.log('ErrorBoundary caught an error:', error, errorInfo);
        }
    }

    render() {
        return this.props.children;
    }
}
