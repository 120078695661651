import { useLocation } from 'react-router-dom';

import { makeRoute, parseRoute } from './routeHelper';
import { useRouteState, useUpdateRoute } from './routeHooks';
import { useAppDispatch, useAppSelector } from '../configuration/setup/hooks';
import {
    getSearchValue,
    getSkipFetchAccount,
    setSearchValue,
    setSkipFetchAccount,
} from '../features/inviteExperts/inviteExpertsSlice.ts';
import { UnknownAction } from '@reduxjs/toolkit';

const RouteUpdater = () => {
    const dispatch = useAppDispatch();
    const { search: locationSearch } = useLocation();

    const currentSearch = useAppSelector(getSearchValue);
    const skipSearchAccount = useAppSelector(getSkipFetchAccount);
    // const selectedAnotherId = useAppSelector(getSelectedAnotherId);

    // Parse initial route or after it has changed by browser navigation or user input
    useRouteState(() => {
        const dispatchQue: UnknownAction[] = [];

        const routeSearchParams = parseRoute(locationSearch);
        const { search } = routeSearchParams;

        if (search && search !== currentSearch) {
            dispatchQue.push(setSearchValue(search));
            dispatchQue.push(setSkipFetchAccount(false));
        }

        dispatchQue.forEach((action: UnknownAction) => dispatch(action));
    });

    // Update route whenever an observed store prop changes.
    // The key of the RouteState is the search param key in the URL.
    const newRoute = makeRoute({
        search: currentSearch,
    });
    useUpdateRoute(newRoute, skipSearchAccount);

    return null;
};

export default RouteUpdater;
