import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { RootState } from '../../configuration/setup/store.ts';
import { Account } from '../../services/types.ts';

export interface InviteExpertsState {
    searchValue: string;
    accountInfo: Account | undefined;
    skipFetchAccount: boolean;
    hasUnsavedChanges: boolean;
}

const initialState: InviteExpertsState = {
    searchValue: '',
    accountInfo: undefined,
    skipFetchAccount: true,
    hasUnsavedChanges: false,
};

const inviteExpertsSlice = createSlice({
    name: 'inviteExperts',
    initialState,
    reducers: {
        setSearchValue: (state, action: PayloadAction<string>) => {
            state.searchValue = action.payload;
        },
        setAccountInfo: (state, action: PayloadAction<Account | undefined>) => {
            state.accountInfo = action.payload;
        },
        setSkipFetchAccount: (state, action: PayloadAction<boolean>) => {
            state.skipFetchAccount = action.payload;
        },
        setHasUnsavedChanges: (state, action: PayloadAction<boolean>) => {
            state.hasUnsavedChanges = action.payload;
        },
    },
});

export const { setSearchValue, setAccountInfo, setSkipFetchAccount, setHasUnsavedChanges } = inviteExpertsSlice.actions;

export const inviteExpertsReducer = inviteExpertsSlice.reducer;

export const getSearchValue = (state: RootState) => state.experts.searchValue;
export const getAccountInfo = (state: RootState) => state.experts.accountInfo;

export const getSkipFetchAccount = (state: RootState) => state.experts.skipFetchAccount;

export const getHasUnsavedChanges = (state: RootState) => state.experts.hasUnsavedChanges;
