import { config } from '../config.ts';
import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';
import { prepareHeaders } from '../configuration/tokenHandling/prepareHeaders.ts';
import { Account, accountSchema } from './types.ts';

const baseUrl = config.backend.ACCOUNTS_SERVICE;

export const accountsApi = createApi({
    reducerPath: 'accountsApi',
    baseQuery: fetchBaseQuery({ baseUrl, prepareHeaders }),
    endpoints: (builder) => ({
        getAccountById: builder.query<Account, { accountId: string }>({
            query: ({ accountId }) => ({
                url: `/${accountId}?embed=(life_cycle_state_information)`,
            }),
            transformResponse: (response) => accountSchema.parse(response),
        }),
    }),
});

export const { useLazyGetAccountByIdQuery, useGetAccountByIdQuery } = accountsApi;
