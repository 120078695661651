export const groupTypes = ['booking_expert', 'analysis_expert'] as const;

export type GroupType = (typeof groupTypes)[number];

export const groupType: Record<GroupType, string> = {
    booking_expert: 'Booking',
    analysis_expert: 'Analysis',
};

export const expertState = {
    DISABLED: 'disabled',
    ENABLED: 'enabled',
    EDIT: 'edit',
} as const;

export type ExpertState = (typeof expertState)[keyof typeof expertState];

export class MdsExpert {
    expertId: string;
    displayName: string;
    email?: string;
    groups: GroupType[];
    state: ExpertState;
    isAlreadyExisting: boolean;
    isInNonManagedGroup: boolean;

    constructor(
        expertId: string,
        displayName: string,
        email: string | undefined,
        groups: GroupType[],
        state: ExpertState,
        isAlreadyExisting: boolean,
        isInNonManagedGroup: boolean
    ) {
        this.expertId = expertId;
        this.displayName = displayName;
        this.email = email;
        this.groups = groups;
        this.state = state;
        this.isAlreadyExisting = isAlreadyExisting;
        this.isInNonManagedGroup = isInNonManagedGroup;
    }

    isInEditMode() {
        return this.state === expertState.EDIT;
    }

    isDisabled() {
        return this.state === expertState.DISABLED;
    }

    isEnabled() {
        return this.state === expertState.ENABLED;
    }
}
