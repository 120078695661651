import qs, { IParseOptions, IStringifyOptions } from 'qs';

export const STRINGIFY_OPTIONS: IStringifyOptions = {
    // required to stringify arrays into comma separated strings
    arrayFormat: 'comma',
    // don't use array indices
    indices: false,
    // don't encode the entire string as it will be done individually for certain params
    encode: false,
    // required to remove empty params
    skipNulls: true,
    strictNullHandling: false,
};

export const PARSE_OPTIONS: IParseOptions = {
    // required to parse comma separated string into array
    comma: true,
};

export interface RouteState {
    search: string | undefined | null;
}

export const parseRoute = (locationSearch: string): RouteState => {
    const decodedSearch = decodeURIComponent(locationSearch.replace('?', ''));
    const { search } = qs.parse(decodedSearch, PARSE_OPTIONS);
    return {
        search,
        // add other url params and convert them to the right type here
        // in order to easily work with them in the RouteUpdater component
    } as RouteState;
};

const clean = (obj: RouteState) => {
    if (obj.search === '') {
        obj.search = null;
    }
    return obj;
};

export const makeRoute = (searchParams: RouteState): string => {
    const queryParams = qs.stringify(clean(searchParams), STRINGIFY_OPTIONS);
    const searchFragment = queryParams && `?${queryParams}`;
    return encodeURI(searchFragment);
};
