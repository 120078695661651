import { combineReducers, configureStore } from '@reduxjs/toolkit';
import { setupListeners } from '@reduxjs/toolkit/query';

import configReducer from './configSlice';
import loginReducer from '../login/loginSlice';
import langReducer from '../lang/langSlice';
import tokenReducer from '../tokenHandling/tokenSlice';
import appReducer from '../../layout/appSlice.ts';
import { accountsApi } from '../../services/accountsApi.ts';
import { expertsApi } from '../../services/expertsApi.ts';
import { inviteExpertsReducer } from '../../features/inviteExperts/inviteExpertsSlice.ts';

const rootReducer = combineReducers({
    config: configReducer,
    lang: langReducer,
    app: appReducer,
    login: loginReducer,
    tokenHandling: tokenReducer,

    experts: inviteExpertsReducer,

    // Add the generated reducer as a specific top-level slice
    [accountsApi.reducerPath]: accountsApi.reducer,
    [expertsApi.reducerPath]: expertsApi.reducer,
});

export const setupStore = (preloadedState?: Partial<RootState>) =>
    configureStore({
        preloadedState,
        reducer: rootReducer,

        // Adding the api middleware enables caching, invalidation, polling,
        // and other useful features of `rtk-query`.
        middleware: (getDefaultMiddleware) =>
            getDefaultMiddleware().concat(accountsApi.middleware, expertsApi.middleware),
    });

export const store = setupStore();

// Infer the `RootState` and `RootDispatch` types from the store itself
export type RootState = ReturnType<typeof rootReducer>;
export type AppStore = ReturnType<typeof setupStore>;
export type AppDispatch = AppStore['dispatch'];

// optional, but required for refetchOnFocus/refetchOnReconnect behaviors
// see `setupListeners` docs - takes an optional callback as the 2nd arg for customization
setupListeners(store.dispatch);
