import { z } from 'zod';
import { groupTypes } from '../features/inviteExperts/expertsManager/types.ts';

export const accountSchema = z
    .object({
        id: z.string(),
        name: z.string(),
        tax_id: z.object({ value: z.string(), tax_type: z.string() }).optional(),
        legal_address: z.object({
            country_code: z.string(),
        }),
    })
    .transform((restAccount) => ({
        id: restAccount.id,
        name: restAccount.name,
        taxId: restAccount.tax_id && {
            value: restAccount.tax_id.value,
            taxType: restAccount.tax_id.tax_type,
        },
        countryCode: restAccount.legal_address.country_code,
    }));

// extract the inferred type
export type Account = z.infer<typeof accountSchema>;

export const expertSchema = z
    .object({
        id: z.string(),
        first_name: z.string(),
        last_name: z.string(),
        email: z.string(),
        country_code: z.string(),
    })
    .transform((restExpert) => ({
        expertId: restExpert.id,
        displayName: `${restExpert.first_name} ${restExpert.last_name}`,
        firstName: restExpert.first_name,
        lastName: restExpert.last_name,
        email: restExpert.email,
        countryCode: restExpert.country_code,
    }));

export type Expert = z.infer<typeof expertSchema>;

export const expertsApiResponseSchema = z.object({ items: z.array(expertSchema) });

export const expertAssignmentSchemaRough = z.object({
    items: z.array(
        z.object({
            account_id: z.string(),
            expert_id: z.string(),
            managed_groups: z.array(z.string()),
            metadata: z.object({
                in_non_managed_groups: z.boolean(),
            }),
        })
    ),
});

const expertAssignmentSchema = z
    .object({
        account_id: z.string(),
        expert_id: z.string(),
        managed_groups: z.array(z.enum(groupTypes)),
        metadata: z.object({
            in_non_managed_groups: z.boolean(),
        }),
    })
    .transform((restExpertAssignment) => ({
        accountId: restExpertAssignment.account_id,
        expertId: restExpertAssignment.expert_id,
        groups: restExpertAssignment.managed_groups,
        metadata: restExpertAssignment.metadata,
    }));

export type ExpertAssignment = z.infer<typeof expertAssignmentSchema>;

export const expertAssignmentsSchemaStrict = z.array(expertAssignmentSchema);
