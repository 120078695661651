import { FormattedMessage } from 'react-intl';
import ApplicationHeader from '@rio-cloud/rio-uikit/ApplicationHeader';
import { config } from '../../config';
import ActionBarItem from '@rio-cloud/rio-uikit/ActionBarItem';
import { useAppSelector } from '../../configuration/setup/hooks.ts';
import { getIdToken } from '../../configuration/tokenHandling/tokenSlice.ts';

const AppHeader = () => {
    const idToken = useAppSelector(getIdToken);

    const logout = () => {
        window.location.href = config.logoutUri!;
    };

    const userInfoItem = (
        <ActionBarItem id={'userInfo'} className={'userInfoItem'}>
            <ActionBarItem.Icon>
                <span className={'icon rioglyph rioglyph-user'} />
            </ActionBarItem.Icon>
            <ActionBarItem.Popover className={'userInfoPopover'}>
                <UserInfo subject={idToken!.sub} email={idToken!.email!} logout={logout} />
            </ActionBarItem.Popover>
        </ActionBarItem>
    );

    return (
        <ApplicationHeader
            label={<FormattedMessage id="intl-msg:bookingExpert.moduleName" />}
            showHomeIcon={false}
            actionBarItems={[userInfoItem]}
        />
    );
};

export default AppHeader;

const UserInfo = ({ subject, email }: { subject: string; email: string; logout: () => void }) => (
    <div>
        <div className={'text-color-dark'}>
            <div className={'text-size-16 clearfix'}>
                <span className={'float-left'}>
                    <FormattedMessage id={'intl-msg:bookingExpert.user.email'} />:
                </span>
                <span className={'float-right'}>{email}</span>
            </div>
            <div className={'text-size-16 clearfix'}>
                <span className={'float-left'}>
                    <FormattedMessage id={'intl-msg:bookingExpert.user.userPool'} />:
                </span>
                <span className={'float-right'}>{subject.split(':')[0]}</span>
            </div>
        </div>
        <hr />
        <div className={'text-center text-size-16 margin-bottom-10'}>
            <a href={config.logoutUri} className={'margin-right-10'}>
                <FormattedMessage id={'intl-msg:bookingExpert.user.logout'} />
            </a>
        </div>
    </div>
);
