import { createHashRouter, createRoutesFromElements, Navigate, Route } from 'react-router-dom';

import { ErrorBoundary } from '../components/ErrorBoundary';
import AppLayout from '../layout/AppLayout';
import { suspendPageLoad } from './suspendPageLoad';

// Lazy load pages for better performance and automatically split the bundle accordingly
const Invite = suspendPageLoad(() => import('../pages/Invite'));

export const routes = { DEFAULT_ROUTE: '/invite' };

export const router = createHashRouter(
    createRoutesFromElements(
        <Route element={<AppLayout />}>
            <Route path={routes.DEFAULT_ROUTE} errorElement={<ErrorBoundary />} element={<Invite />} />
            <Route path="*" element={<Navigate to={routes.DEFAULT_ROUTE} />} />
        </Route>
    )
);
