import { config } from '../config.ts';
import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';
import { prepareHeaders } from '../configuration/tokenHandling/prepareHeaders.ts';
import {
    Expert,
    ExpertAssignment,
    expertAssignmentSchemaRough,
    expertAssignmentsSchemaStrict,
    expertsApiResponseSchema,
} from './types.ts';
import { groupTypes } from '../features/inviteExperts/expertsManager/types.ts';

const baseUrl = config.backend.BOOKING_EXPERTS_SERVICE;

export interface ExpertAssignmentExtended extends ExpertAssignment {
    toBeAdded?: boolean;
    toBeRemoved?: boolean;
}

export const expertsApi = createApi({
    reducerPath: 'expertsApi',
    baseQuery: fetchBaseQuery({ baseUrl, prepareHeaders }),
    tagTypes: ['Experts', 'ExpertAssignment'],
    endpoints: (builder) => ({
        getAllExperts: builder.query<Expert[], void>({
            query: () => ({
                url: '/experts',
            }),
            transformResponse: (response) => expertsApiResponseSchema.parse(response).items,
            providesTags: ['Experts'],
        }),

        getExpertAssignmentForAccount: builder.query<ExpertAssignmentExtended[], { accountId: string }>({
            query: ({ accountId }) => ({
                url: `/expert-assignments/${accountId}`,
            }),
            transformResponse: (response) => {
                const items = expertAssignmentSchemaRough.parse(response).items;
                // to make things more robust with changes in the backend, we filter for known managed groups
                const prefilteredItems = items.map((item) => ({
                    ...item,
                    managed_groups: item.managed_groups.filter((it: any) => groupTypes.includes(it)),
                }));
                return expertAssignmentsSchemaStrict.parse(prefilteredItems);
            },
            providesTags: (_, __, arg) => [{ type: 'ExpertAssignment', id: arg.accountId }],
        }),

        putExpertAssignment: builder.mutation<void, { accountId: string; expertId: string; managedGroups: string[] }>({
            query: ({ accountId, expertId, managedGroups }) => ({
                url: `/expert-assignments/${accountId}/${expertId}`,
                method: 'PUT',
                body: { expert_id: expertId, account_id: accountId, managed_groups: managedGroups },
            }),
            invalidatesTags: (_, __, arg) => [{ type: 'ExpertAssignment', id: arg.accountId }],
        }),

        deleteExpertAssignment: builder.mutation<void, { accountId: string; expertId: string }>({
            query: ({ accountId, expertId }) => ({
                url: `/expert-assignments/${accountId}/${expertId}`,
                method: 'DELETE',
            }),
            invalidatesTags: (_, __, arg) => [{ type: 'ExpertAssignment', id: arg.accountId }],
        }),
    }),
});

export const {
    useGetAllExpertsQuery,
    useGetExpertAssignmentForAccountQuery,
    usePutExpertAssignmentMutation,
    useDeleteExpertAssignmentMutation,
} = expertsApi;
